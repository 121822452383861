.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-title {
  margin-bottom: 10px;
}

.home-container,
.download-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.home-container > .home-card {
  height: 544px;
  max-width: 100%;
  margin-top: 75px;
  width: 920px;
}

.account-search-container {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.primary-home-card-reduced {
  width: 920px;
  margin-top: 75px;
}

.additional-home-card-reduced {
  width: 920px;
  margin-top: 45px;
}

.home-card-content {
  margin-top: 100px;
}

.home-card-content-reduced {
  margin-top: 50px;
  margin-bottom: 50px;
}

.home-card-main-text {
  text-align: center;
}

.action-card-text {
  line-height: 30px;
}

.action-card-container {
  text-align: left;
}

.download-button {
  margin-top: 44px;
  margin-left: 14px;
}

.custom-help-text {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  margin: -0.375rem 0px 0.375rem;
}

.contact-type-success-alert {
  margin-top: 10px;
}

.alert-message {
  margin-top: 0px;
}

.home-page-text {
  padding-bottom: 30px;
}

@media (max-width: 600px) {
  .homepage-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .home-container,
  .download-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .home-container > .home-card {
    margin-top: 30px;
  }

  .account-search-container {
    flex: 1;
    max-width: 100%;
  }

  .primary-home-card-reduced,
  .additional-home-card-reduced {
    width: 100%;
    margin-top: 30px;
  }

  .home-card-content {
    margin-top: 50px;
  }

  .home-card-content-reduced {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .home-card-main-text {
    text-align: center;
  }

  .action-card-container {
    text-align: center;
  }

  .download-button {
    margin-top: 30px;
    margin-left: 0;
    order: 1;
  }

  .custom-help-text {
    margin: 0;
  }

  .contact-type-success-alert {
    margin-top: 5px;
  }

  .home-page-text {
    padding-bottom: 20px;
  }
}

/* @media  */
