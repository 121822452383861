.navbar-search-text {
  margin-right: 10px;
}

.search-helper-text {
  padding-top: 15px;
}

.tooltip-helper-text {
  width: 5%;
  cursor: pointer;
}

.user-greeting {
  position: absolute;
  left: 20px;
  padding-right: 40px;
  order: 2;
}

.navbar-search {
  width: calc(100vw - 80px);
  text-align: right;
}

@media (min-width: 620px) {
  .user-greeting {
    position: relative;
    left: 0;
    margin-left: 50px;
  }

  .navbar-search {
    width: auto;
  }
}
