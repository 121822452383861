.search-results-page .stacked-cell:nth-last-child(-n + 2) {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.search-results-page .stacked-cell:nth-last-child(1) {
  align-items: flex-end;
}

.search-results-page .stacked-cell:nth-last-child(2) {
  float: left;
  align-items: flex-start;
}

.search-results-page .footer {
  margin-top: -50px;
}

@media (max-width: 825px) {
  .search-results-page .stacked-cell:nth-last-child(-n + 2) {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .search-results-page .stacked-cell:nth-last-child(1) {
    align-items: flex-end;
  }

  .search-results-page .stacked-cell:nth-last-child(2) {
    float: left;
    align-items: flex-start;
  }

  .search-results-page .footer {
    margin-top: -50px; /* Adjust this value to leave space for the create-contact-button */
  }

  .create-contact-button {
    position: sticky;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 10px;
    padding-top: 20px;
    background-color: white;
    width: 100%;
    justify-content: center;
    border-top: 1px solid lightgray;
  }

  .create-contact-button .tds-icon-24 {
    margin-right: 10px;
  }

  .create-contact-button .icon-link-text {
    margin: 0;
    white-space: nowrap;
  }
}
