.home-download-container {
  display: flex;
}

.download-drop-down {
  flex: 1;
}

.download-contact-button {
  flex: 1;
  padding-top: 20px;
  padding-left: 20px;
}

@media (max-width: 737px) {
  .home-download-container {
    flex-direction: column;
    text-align: center;
  }

  .custom-help-text {
    text-align: left; /* Align the text to the left */
    margin-right: 10px; /* Add some margin to create space between the text and the dropdown menu */
  }

  .download-drop-down {
    width: 80%;
    align-self: center;
    position: relative;
    z-index: 2;
  }

  .download-contact-button {
    width: 80%; /* Full width for both elements */
    align-self: center; /* Move the button to the top */
    padding: 0; /* Remove padding */
    position: relative;
    z-index: 1;
    margin-top: -30px; /* Adjust the negative margin to overlap the dropdown */
  }
}
