.unauthorized-header-font {
    font-size: 48px;
    font-weight: 300;
}

.unauthorized-center {
    text-align: center;
}

.unauthorized-header {
    padding-top: 130px;
}

.unauthorized-alerts {
    padding-top: 40px;
}

.unauthorized-p1-style {
    font-size: 24px;
    font-weight: 350;
    padding-bottom: 7px;
}

.unauthorized-p3-style {
    font-size: 20px;
    font-weight: 400;
}

.unauthorized-p2-style {
    font-size: 20px;
    font-weight: 350;
}

a:link {
    font-weight: 400;
    color: #007395;
}

.alert-container-unatherorized-user {
    width: 95%;
}