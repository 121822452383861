.search-results-page {
  margin: 80px 60px;
}

.search-results-title {
  margin: 0;
  font-weight: 400;
}

.search-results-sub-title {
  margin: 1 rem;
  font-weight: 400;
}

.search-results-account-copy {
  margin: 60px 0 80px 0;
}

.actions-buttons {
  width: 100%;
  cursor: pointer;
}

.icon-link {
  display: inline;
  vertical-align: middle;
  padding-right: 5px;
  filter: invert(32%) sepia(100%) saturate(675%) hue-rotate(155deg)
    brightness(89%) contrast(101%);
}

.icon-link-text {
  display: inline;
  font-size: 18px;
  vertical-align: middle;
  color: #007395;
}

.row-needs-attention {
  background-color: #fef8f2 !important;
}

.needs-attention-icon {
  float: right;
  filter: invert(62%) sepia(57%) saturate(6157%) hue-rotate(5deg)
    brightness(90%) contrast(95%);
}

.verified-contact-icon {
  float: right;
  filter: invert(52%) sepia(60%) saturate(523%) hue-rotate(89deg)
    brightness(90%) contrast(92%);
}

.needs-attention-alert {
  height: 80px;
}

.create-contact-button {
  cursor: pointer;
  width: 200px;
}

.status-bold {
  font-weight: 700;
}
