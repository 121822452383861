.tds-container .add-contact-card {
  width: 700px;
  max-width: 100%;
  margin: auto;
  margin-top: 50px;
  padding: 50px 0px;
}

.alert-container {
  margin-top: 50px;
}

.add-contact-card-main-text {
  text-align: center;
}

.add-contact-card-search-text {
  line-height: 30px;
}

.input-fields-container {
  width: 600px;
  max-width: 100%;
  margin: auto;
  margin-top: 30px;
}

.contact-types-check-boxes {
  width: 400px;
  max-width: 100%;
  text-align: left;
}

.primary-contact-checkbox {
  width: max-content;
  max-width: 100%;
}

.form-text-field-inputs {
  text-align: left;
}

#learn-more-modal {
  color: #272a2d;
}

.learn-more-modal-link p {
  margin-bottom: 0px;
  font-size: 14px;
}

.learn-more-modal-link a {
  text-decoration: unset;
}

.learn-more-modal-link a:hover {
  color: #006380;
  text-decoration: underline;
  background: #0091EB 20% opacity;
}

.learn-more-modal-link a:focus {
  color: #006380;
  text-decoration: underline;
  background: #0091EB 20% opacity;
}

.form-alert {
  width: 100%;
}

.modal-title {
  text-align: center;
  font-size: 24px;
}

.learn-more-table {
  font-size: 12px;
}

.modal-borderline-under {
  padding-bottom: 10px;
  border-bottom: 0.0625rem solid #6b6d71;
}

.add-account-name-title {
  font-weight: 300;
}

.lst-mod-bold {
  font-weight: 600;
  font-size: 18px;
}

.lst-mod {
  font-weight: 300;
}

.line-top {
  padding-top: 17px;
  border-top: 2px solid #dadbdb;
}

.form-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.contact-type-container {
  display: inline-block;
  margin-right: -0.75em;
}

.contact-type-width {
  cursor: pointer;
  width: 120%;
}

.tooltip-text {
  width: max-content;
  max-width: 12ch;
  white-space: normal;
  margin: 0;
}

@media (min-width: 30rem) {
  .form-buttons {
    flex-direction: row;
    justify-content: center;
  }

  .submit-button {
    padding-right: 20px;
  }
}

@media (min-width: 26rem) {
  .tooltip-text {
    max-width: 20ch;
  }
}

@media (min-width: 35rem) {
  .tooltip-text {
    max-width: none;
  }
}
