.header {
    padding-top: 40px;
    padding-bottom: 10px;
    font-size: 42px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0px;
    text-align: left;
 }

 .caption-text {
    overflow-wrap: break-word;
 }

 .account-name-row:hover {
   cursor: pointer;
   color: #007395;
   font-weight: 600;
 }

 .actions-buttons{
   cursor: pointer;
}

.icon-link{
   display: inline;
   vertical-align: middle;
   filter: invert(32%) sepia(100%) saturate(675%) hue-rotate(155deg) brightness(89%) contrast(101%);
}
